<template>
  <figure class="gallery-image" @click="showModal(index)">
    <img v-if="image.src" :class="[imageClass]" :src="image.src" />
  </figure>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {

  mixins: [inViewport],

  props: {
    index: {
      type: Number,
      default: null
    },

    image: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isVisible: false
    }
  },

  computed: {
    imageClass () {
      return this.image.hasLoaded ? 'is-visible' : ''
    }
  },

  watch: {
    'inViewport.now' (isVisible) {
      if (isVisible && !this.image.src) {
        this.readMedia()
      }
    }
  },

  methods: {
    async readMedia () {
      if (this.image.hasLoaded) return

      try {
        const src = await this.$store.dispatch('huntarea/images/readMedia', this.image.imagePath)
        this.image.src = src

        setTimeout(() => {
          this.image.hasLoaded = true
        }, 250)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('gallery.loadImageFailedErrorMessage'))
        console.error(error)
      }
    },

    showModal (index) {
      this.$emit('showModal', index)
    }
  }

}
</script>

<style lang="scss" scoped>
.gallery {
  .gallery-image {
    cursor: pointer;
    display: block;
    position: relative;
    padding-top: 100%;
    border-radius: 0.25rem;
    background: rgb(167, 167, 167);
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 0.25rem;
      filter: blur(1.5rem);
      transition: all 0.5s ease-out;
      transition-delay: 0.25s;

      &.is-visible {
        filter: blur(0);
      }
    }
  }
}
</style>
