<template>
  <Fragment>
    <Wrapper v-if="!isIE" class="subpage page-gallery">
      <Titlebar :isLoading="!images">
        <h5 slot="title" class="title is-5 is-marginless">
          {{ $t('gallery.title') }}
        </h5>
      </Titlebar>

      <div v-if="images && images.length" class="gallery">
        <GalleryImage
          v-for="(image, i) in images"
          :key="i"
          :index="i"
          :image="image"
          @showModal="showModal">
        </GalleryImage>
      </div>

      <div v-if="!images" class="gallery">
        <div
          v-for="i in 6"
          :key="`${i}-loader`"
          class="gallery-image">
        </div>
      </div>

      <EmptyState
        v-if="images && !images.length"
        vertically-aligned
        icon="icon-image"
        :tagline="$t('gallery.noImages')">
      </EmptyState>

      <GalleryModal />
    </Wrapper>

    <Wrapper v-else class="subpage page-gallery">
      <Titlebar>
        <h5 slot="title" class="title is-5 is-marginless">
          {{ $t('gallery.title') }}
        </h5>
      </Titlebar>

      <EmptyState
        vertically-aligned
        icon="icon-chrome"
        :tagline="$t('general.unsupportedBrowserMessage')">
      </EmptyState>
    </Wrapper>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

import GalleryImage from './GalleryImage.vue'
import GalleryModal from './GalleryModal.vue'

export default {
  components: {
    GalleryImage,
    GalleryModal
  },

  computed: {
    ...mapGetters({
      data: 'huntarea/images/images'
    }),

    images () {
      return this.data && this.data.slice().sort((a, b) => b.createdAt - a.createdAt)
    },

    isIE () {
      return this.$utils.isInternetExplorer()
    }
  },

  methods: {
    showModal (index) {
      this.$store.commit('huntarea/images/setCurrentImage', index)
    }
  }
}
</script>

<style lang="scss">
$grid: (
  "640": 2,
  "1024": 3,
  "1400": 4,
  "1920": 5,
  "2560": 6
);

.page-gallery {
  display: flex;
  flex-direction: column;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;

  @each $viewport, $columns in $grid {
    @media screen and (min-width: #{$viewport}px) {
      grid-template-columns: repeat($columns, 1fr);
    }
  }
}
</style>
