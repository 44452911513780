import { render, staticRenderFns } from "./GalleryModal.vue?vue&type=template&id=3db8d1ba&scoped=true&"
import script from "./GalleryModal.vue?vue&type=script&lang=js&"
export * from "./GalleryModal.vue?vue&type=script&lang=js&"
import style0 from "./GalleryModal.vue?vue&type=style&index=0&id=3db8d1ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db8d1ba",
  null
  
)

export default component.exports