<template>
  <div :class="{ 'is-active': isActive }" class="modal gallery-modal">
    <div class="modal-background" @click="hideModal"></div>

    <div class="modal-content">
      <!-- <h5 v-if="image && image.createdAt" class="has-text-white has-text-centered title is-5">
        {{ date(image.createdAt) }}
      </h5> -->

      <span
        v-if="currentImage !== 0"
        class="prev-next push-right-xxs"
        @click="prev">
        <Icon name="icon-chevron-left" class="is-large" />
      </span>

      <figure v-if="image" class="image">
        <img :src="image.src" :title="date(image.createdAt)" />
      </figure>

      <span
        v-if="currentImage !== (images && images.length - 1)"
        class="prev-next push-left-xxs"
        @click="next">
        <Icon name="icon-chevron-right" class="is-large" />
      </span>
    </div>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="hideModal">
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import * as _ from '@/assets/libs/utils/'

export default {

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    ...mapGetters({
      data: 'huntarea/images/images'
    }),

    currentImage: {
      get () {
        return this.$store.getters['huntarea/images/currentImage']
      },

      set (index) {
        if (index) {
          this.isActive = true
        }

        return this.$store.commit('huntarea/images/setCurrentImage', index)
      }
    },

    images () {
      return this.data && this.data.slice().sort((a, b) => b.createdAt - a.createdAt)
    },

    image () {
      return this.images && this.images[this.currentImage]
    }
  },

  watch: {
    currentImage (index) {
      this.isActive = index !== null
    }
  },

  mounted () {
    document.addEventListener('keyup', this.go)
  },

  methods: {
    date (epoch) {
      return _.capitalize(dayjs(epoch).format('ddd DD MMM HH:mm'))
    },

    hideModal () {
      this.isActive = false
      this.currentImage = null
    },

    go (event) {
      switch (event.keyCode) {
        case 37:
          this.prev()
          break

        case 39:
          this.next()
          break

        case 27:
          this.hideModal()
          break
      }
    },

    prev () {
      if (this.currentImage === 0) return
      this.currentImage--
    },

    next () {
      if (this.currentImage === this.images.length - 1) return
      this.currentImage++
    }
  }

}
</script>

<style lang="scss" scoped>
.gallery-modal {
  .modal-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 640px;
  }

  .image {
    display: flex;
    flex-grow: 1;

    img {
      object-fit: contain;
      max-height: 640px;
    }
  }

  .prev-next {
    cursor: pointer;
    color: white;
    padding: 0.75rem;
    font-size: 1.5rem;
    background: transparent;
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }
}
</style>
